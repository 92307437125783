'use client';

import { PropsWithChildren } from 'react';
import { Button } from '@mui/material';
export const ChatNowButton = ({
  children
}: PropsWithChildren) => {
  const handleClick = () => {
    window?.salesPilotPostMessages?.openChatPage();
  };
  return <Button onClick={handleClick} variant="contained" color="secondary" size="large" fullWidth data-sentry-element="Button" data-sentry-component="ChatNowButton" data-sentry-source-file="ChatNowButton.tsx">
      {children}
    </Button>;
};