'use client';

import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Button } from '@mui/material';
export const SearchPartsButton = () => {
  const handleClick = () => {
    window?.salesPilotPostMessages?.openSearchPage();
  };
  return <Button onClick={handleClick} variant="contained" color="secondary" fullWidth sx={{
    fontSize: 20,
    px: 6,
    height: 60,
    justifyContent: {
      xs: 'center',
      md: 'flex-start',
      xxl: 'center'
    }
  }} endIcon={<ChevronRightIcon />} data-sentry-element="Button" data-sentry-component="SearchPartsButton" data-sentry-source-file="SearchPartsButton.tsx">
      Search parts
    </Button>;
};