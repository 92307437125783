'use client';

import { PropsWithChildren } from 'react';
import Slider, { Settings } from 'react-slick';
import { Box, useTheme } from '@mui/material';
import { ReactSlickStyle } from '@qb/frontend/components/ReactSlickStyle/ReactSlickStyle';
import { CarouselArrow } from './CarouselArrow';
type Props = PropsWithChildren<{
  slidesToShow: {
    xs: number;
    sm: number;
    md: number;
    lg: number;
    xl: number;
  };
  slidesCount: number;
}>;
export const BaseCarousel = ({
  slidesCount,
  slidesToShow = {
    xs: 1,
    sm: 2,
    md: 3,
    lg: 4,
    xl: 4
  },
  children
}: Props) => {
  const theme = useTheme();
  const carouselSettings: Settings = {
    dots: false,
    slidesToShow: slidesToShow.xl,
    infinite: hasEnoughItemsForSlider(slidesCount, slidesToShow.xl),
    initialSlide: 0,
    speed: 500,
    swipeToSlide: true,
    cssEase: theme.transitions.easing.easeInOut,
    prevArrow: <CarouselArrow variant="prev" />,
    nextArrow: <CarouselArrow variant="next" />,
    responsive: [{
      breakpoint: theme.breakpoints.values.xl,
      settings: {
        slidesToShow: slidesToShow.lg,
        infinite: hasEnoughItemsForSlider(slidesCount, slidesToShow.lg)
      }
    }, {
      breakpoint: theme.breakpoints.values.lg,
      settings: {
        slidesToShow: slidesToShow.md,
        infinite: hasEnoughItemsForSlider(slidesCount, slidesToShow.md)
      }
    }, {
      breakpoint: theme.breakpoints.values.md,
      settings: {
        slidesToShow: slidesToShow.sm,
        infinite: hasEnoughItemsForSlider(slidesCount, slidesToShow.sm),
        centerPadding: '30px'
      }
    }, {
      breakpoint: theme.breakpoints.values.sm,
      settings: {
        slidesToShow: slidesToShow.xs,
        infinite: hasEnoughItemsForSlider(slidesCount, slidesToShow.xs),
        centerMode: false
      }
    }]
  };
  return <>
      <ReactSlickStyle data-sentry-element="ReactSlickStyle" data-sentry-source-file="BaseCarousel.tsx" />
      <Box sx={{
      px: 0,
      '& .slick-slider': {
        display: 'grid'
      },
      '& .slick-list': {
        pl: 0,
        mx: {
          xs: 0,
          sm: -1
        }
      },
      '& .slick-dots': {
        bottom: theme.spacing(-5)
      },
      '& .slick-dots li': {
        margin: 0
      },
      '& .slick-dots li button': {
        p: 1
      },
      '& .slick-dots li button:before': {
        fontSize: 11,
        color: 'action.disabled'
      },
      '& .slick-dots li.slick-active button:before': {
        color: 'primary.main',
        opacity: 1
      }
    }} data-sentry-element="Box" data-sentry-source-file="BaseCarousel.tsx">
        <Slider {...carouselSettings} data-sentry-element="Slider" data-sentry-source-file="BaseCarousel.tsx">{children}</Slider>
      </Box>
    </>;
};
const hasEnoughItemsForSlider = (slidesCount: number, slidesToShowCount: number) => slidesCount > slidesToShowCount;