'use client';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { IconButton } from '@mui/material';
type CarouselArrowProps = {
  onClick?: () => void;
  variant: 'prev' | 'next';
};
export const CarouselArrow = ({
  onClick,
  variant
}: CarouselArrowProps) => <IconButton aria-label={variant === 'next' ? 'Next slide' : 'Previous slide'} onClick={onClick} color="primary" sx={theme => ({
  width: {
    xs: 40,
    lg: 56
  },
  height: {
    xs: 40,
    lg: 56
  },
  position: 'absolute',
  top: '50%',
  transform: 'translate(0, -50%)',
  right: variant === 'next' ? {
    xs: -10,
    sm: -20,
    lg: -28
  } : 'auto',
  left: variant === 'prev' ? {
    xs: -10,
    sm: -20,
    lg: -28
  } : 'auto',
  p: 0,
  zIndex: theme.zIndex.mobileStepper,
  boxShadow: theme.shadows[6],
  backgroundColor: 'common.white',
  '&:hover, &:focus': {
    backgroundColor: 'common.white',
    textDecoration: 'underline'
  }
})} data-sentry-element="IconButton" data-sentry-component="CarouselArrow" data-sentry-source-file="CarouselArrow.tsx">
    {variant === 'next' ? <ArrowForwardIcon sx={{
    fontSize: 24
  }} /> : <ArrowBackIcon sx={{
    fontSize: 24
  }} />}
  </IconButton>;