import { Button, Typography, Grid } from '@mui/material';
type ContactUsButtonProps = {
  href?: string;
  onClick?: () => void;
  icon: React.ReactNode;
  children: React.ReactNode;
};
export const ContactUsButton = ({
  href,
  onClick,
  icon,
  children
}: ContactUsButtonProps) => <Grid item xs={12} md={4} data-sentry-element="Grid" data-sentry-component="ContactUsButton" data-sentry-source-file="ContactUsButton.tsx">
    <Button href={href} onClick={onClick} variant="outlined" size="large" startIcon={icon} color="secondary" fullWidth sx={{
    height: 'unset',
    justifyContent: 'flex-start',
    borderWidth: 2,
    borderRadius: 0,
    borderColor: 'secondary.main',
    backgroundColor: 'common.white',
    p: 0,
    overflow: 'hidden',
    '&:hover, &:focus': {
      borderWidth: 2,
      borderRadius: 0
    },
    '.MuiButton-startIcon': {
      backgroundColor: 'secondary.main',
      color: 'primary.main',
      minWidth: 56,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      alignSelf: 'stretch',
      m: 0
    }
  }} data-sentry-element="Button" data-sentry-source-file="ContactUsButton.tsx">
      <Typography component="span" p={2} pl={[2, 2, 2, 4]} textAlign="left" sx={{
      width: '100%',
      wordBreak: 'break-word',
      color: 'text.primary'
    }} data-sentry-element="Typography" data-sentry-source-file="ContactUsButton.tsx">
        {children}
      </Typography>
    </Button>
  </Grid>;