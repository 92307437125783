'use client';

import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined';
import { Grid } from '@mui/material';
import { ContactUsButton } from '@qb/powermatic/app/(www)/_components/ContactButtons/ContactUsButton';
export const ContactButtons = () => {
  const handleClick = () => {
    window?.salesPilotPostMessages?.openChatPage();
  };
  return <Grid container rowSpacing={{
    xs: 1,
    md: 1
  }} columnSpacing={{
    md: 2,
    lg: 7
  }} data-sentry-element="Grid" data-sentry-component="ContactButtons" data-sentry-source-file="ContactButtons.tsx">
      <ContactUsButton onClick={handleClick} icon={<ChatOutlinedIcon />} data-sentry-element="ContactUsButton" data-sentry-source-file="ContactButtons.tsx">
        Start the chat
      </ContactUsButton>
      <ContactUsButton icon={<MailOutlineIcon />} href="mailto:insideSales@powermatic.net" data-sentry-element="ContactUsButton" data-sentry-source-file="ContactButtons.tsx">
        insideSales@powermatic.net
      </ContactUsButton>
      <ContactUsButton href="tel:+18009668513" icon={<PhoneOutlinedIcon />} data-sentry-element="ContactUsButton" data-sentry-source-file="ContactButtons.tsx">
        Call: 800-966-8513
      </ContactUsButton>
    </Grid>;
};