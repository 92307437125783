'use client';

import { useState, PropsWithChildren } from 'react';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Button, Box, Collapse } from '@mui/material';
export const MoreAuthorizedBrands = ({
  children
}: PropsWithChildren) => {
  const [isVisible, setIsVisible] = useState(false);
  return <>
      <Collapse in={isVisible} data-sentry-element="Collapse" data-sentry-source-file="MoreAuthorizedBrands.tsx">{children}</Collapse>
      <Box sx={{
      display: 'flex',
      justifyContent: 'center',
      mt: 2
    }} data-sentry-element="Box" data-sentry-source-file="MoreAuthorizedBrands.tsx">
        <Button onClick={() => setIsVisible(prev => !prev)} variant="contained" color="secondary" sx={{
        minWidth: {
          xs: 200,
          sm: 300
        }
      }} endIcon={isVisible ? <ExpandLessIcon /> : <ExpandMoreIcon />} data-sentry-element="Button" data-sentry-source-file="MoreAuthorizedBrands.tsx">
          {`See ${isVisible ? 'less' : 'more'}`}
        </Button>
      </Box>
    </>;
};